/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "modern-technologies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modern-technologies",
    "aria-label": "modern technologies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modern Technologies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audéo Q employs cutting-edge audiology technologies by ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/",
    className: "c-md-a"
  }, "Phonak"), ". One of Audéo Q’s premium functions is the automatic focus on speech, auto StereoZoom. It automatically hones in on the voice of the very person that the user captures visually. Even in a high noise level environment, the speaker can be understood with clarity. Another premium feature is ‘speech in wind’, providing reliable speech intelligibility even when it gets windy. There is a relatively high probability of a hearing impairment to be accompanied by a sustained ringing of the ears (tinnitus). The Audéo Q, therefore, features a standard ‘tinnitus balance noiser’ producing a pleasant signal noise that alleviates the hardships of tinnitus."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wind noise elimination: Enables clear hearing without wind noises"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Acoustic feedback reduction: Suppression of irritating whistling from the hearing device"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Frequency shift: Makes high-pitch range effectively audible again"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Connectivity: Wireless connection with external audio sources"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "versions-and-design",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#versions-and-design",
    "aria-label": "versions and design permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Versions and Design"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As an exclusively behind-the-ear (BTE) ", React.createElement(_components.a, {
    href: "/hearing-aids/types/",
    className: "c-md-a"
  }, "hearing aid model"), ", the Audéo Q is offered in three different versions, aptly addressing minor to severe hearing impairment, respectively. BTE-devices feature an inconspicuous behind-the-ear placement of the ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "hearing aid"), " casing and only a tube with the diameter of a pinhead leads to the inner ear, transmitting the sound. The color options for the exterior design of the Audéo Q are particularly numerous: Besides the traditional hair and skin tones, housings are available in eye-catching trend and fashion colors."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Phonak Audéo Q now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wireless-communication-and-listening-to-music",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wireless-communication-and-listening-to-music",
    "aria-label": "wireless communication and listening to music permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wireless Communication and Listening to Music"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Good news for hearing aid users who don’t want to compromise comfort while talking on the phone or listening to music: The Phonak Audéo Q is compatible with the Phonak wireless communication portfolio. This means that audio signals from cell, TV and other entertainment devices are transferred to the hearing devices wirelessly and can be enjoyed in excellent sound quality. Phonak’s wireless and accessory portfolio offers the Audéo Q user a wide range of options and a comfortable hearing experience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "risk-free-trial",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#risk-free-trial",
    "aria-label": "risk free trial permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Risk-free trial"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you seeking advice regarding the Phonak Audéo Q or would like to receive information about hearing aids and hearing impairment? Then give us a call! Please do not hesitate to talk to our hearing aid experts over the phone – your inquiry is non-binding, free of charge, and neutral. Moreover, hear.com offers an ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "online hearing test"), " and much more information covering topics such as tinnitus or age-related hearing loss (presbyacusis)."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Phonak Audéo Q now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
